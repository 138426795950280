<template>
  <HomePage
    :homeBanner="homeBanner"
    :homeIntroduction="homeIntroduction"
    :homeThemes="homeThemes"
    :homeTrips="homeTrips"
    :homePromotion="homePromotion"
    :homePurpose="homePurpose"
    :homeUSPs="homeUSPs"
    :homeBlogs="homeBlogs"
    :page="page"
    :travelAlert="travelAlertProps"
  />
</template>

<script lang="ts">
import Vue from "vue";
import { InitPageParams } from "lib/utils/initPage";
import HomePage from "~/components/HomePage/HomePage.vue";
import { HomePageProps } from "~~/components/HomePage/Props";
import { initPage } from "~~/lib/utils/initPage";
import { getBaseLink } from "~/lib/utils/link/getBaseLink";

export default Vue.extend({
  name: "HomePreview",
  components: {
    HomePage,
  },
  layout: "preview",
  async asyncData({
    params,
    i18n,
    $config,
    route,
    store,
  }): Promise<HomePageProps> {
    const requestParams: InitPageParams = {
      locale_iso: i18n.localeProperties.iso,
      locale_code: i18n.locale,
      locale_domain: $config.public.baseUrl,
      path: route.path,
      slug: `/${params.pathMatch}`,
      currency_code: params.currencyCode || i18n.localeProperties.currencyCode,
      live_preview: route.query?.live_preview ?? undefined,
      content_type_uid: route.query?.content_type_uid ?? undefined,
      current_path: getBaseLink(route.path),
    };

    await Promise.all(initPage(store, requestParams));

    const homePage = (await $fetch("/api/nuxt/home/page", {
      params: requestParams,
    })) as HomePageProps;

    return { ...homePage };
  },
  computed: {
    travelAlertProps(): HomePageProps["travelAlert"] {
      return this.travelAlert ? this.travelAlert : undefined;
    },
  },
  mounted() {
    this.$initLivePreview();
  },
});
</script>
